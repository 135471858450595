import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
  Upload,
  notification,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../../components/UI/Button/Button';
import TableComponent from '../../../../../../components/UI/Table/TableComponent';
import { errorNotification, successNotification } from '../../../../../../components/UI/Toast/Toast';
import { PRICING_MATRIX_TEMPLATE } from '../../../../../../constants/types';
import { errorHandler, toTitleCase } from '../../../../../../utils/helper';
import api from '../../../../../../api/api';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BookingSourceModal } from '../../../../../../components/UI/Modals/CustomModal';

interface Props {
  name: any;
  restField: any;
  initialData: any;
  form: any;
  setHelpModalVisible: any;
  transaction_only: boolean;
  remove: any;
  updateButtonState: any;
  getBookingSource: VoidFunction;
}

const AddPolicyTransactions: React.FC<Props> = ({
  initialData,
  form,
  name,
  restField,
  setHelpModalVisible,
  transaction_only,
  remove,
  updateButtonState,
  getBookingSource
}) => {
  const {choices, bookingSources, coverageList} = useSelector((state: any) => state?.masterdata),
    [pricingMatrix, setPricingMatrix] = useState<any>(undefined),
    [valid, setValid] = useState<boolean>(false),
    [disable, setDisable] = useState<boolean>(true),
    [disableIntent, setDisableIntent] = useState<boolean>(true),
    [disablePolicyDetails, setDisablePolicyDetails] = useState<boolean>(true),
    [intent, setIntent] = useState<boolean>(true),
    [disableBalance, setDisableBalance] = useState<boolean>(false),
    [tpaList, setTpaList] = useState<Array<any>>([]),
    [planList, setPlanList] = useState<Array<any>>([]),
    [validationArray, setValidationArray] = useState<any | null>(null),
    [mandatoryArray, setMandatoryArray] = useState<any>([]),
    [updatedFlag, setUpdatedFlag] = useState<boolean>(false),
    [sourceModal, setSourceModal] = useState<boolean>(false),
    XLSX = require('xlsx'),
    tooltopDefaultDeductible =
      'Amount selected here will be the default deductible shown to the members of the client. However, members can select any deductible amount from the available options.',
    columns = [
      {
        title: 'Age Band',
        dataIndex: ['Upper age band'],
        render: (value: string, data: any) => {
          return `${data?.['lower_age_band']} - ${data?.['upper_age_band']}`;
        },
      },
    ],
    generateCols = (data: any) => {
      let keys = Object?.keys(data?.[0])
        ?.map((key: any) => {
          if (parseInt(key)) return key;
        })
        ?.filter((key: any) => key);
      let item = keys?.map((key: any) => {
        return {
          title: '₹ ' + key / 100000 + ' Lakhs',
          dataIndex: key?.toLocaleString('en-IN'),
          render: (value: string, data: any) => {
            return `₹ ${data?.[key]?.toLocaleString('en-IN')}`;
          },
        };
      }) as any;
      return item;
    },
    checkValidity = () => {
      ['policy', 'retail']?.includes(initialData?.[name]?.type)
        ? checkPolicyValidity(initialData?.[name]?.newProduct || false)
        : checkSubscriptionValidity(initialData?.[name]?.newProduct || false);
    },
    checkPolicyValidity = (newProduct: boolean) => {
      let flag = true;
      // Policy Validation
      if (newProduct && validationArray?.display?.length > 0 && initialData?.[name]?.required_fields?.length > 0) {
        validationArray?.display?.forEach((item: any) => {
          if (initialData?.[name]?.required_fields?.includes(item)) {
            if (
              form.getFieldValue(['products', name, item]) === null ||
              form.getFieldValue(['products', name, item]) === undefined
            ) {
              flag = false;
              return;
            }
          }
        });
      }
      // Transaction Validation
      if (flag && intent && validationArray?.transaction?.length !== 0) {
        validationArray?.transaction?.forEach((item: any) => {
          if (item !== 'rewards_amount') {
            if (
              form.getFieldValue(['products', name, item]) === null ||
              form.getFieldValue(['products', name, item]) === undefined
            ) {
              flag = false;
              return;
            }
          }
        });
      }
      setValid(flag);
    },
    checkSubscriptionValidity = (newProduct: boolean) => {
      let flag = true;
      // Policy Validation
      if (newProduct && validationArray?.display?.length > 0 && initialData?.[name]?.required_fields?.length > 0) {
        validationArray?.display?.forEach((item: any) => {
          if (initialData?.[name]?.required_fields?.includes(item)) {
            if (item === 'plan') {
              if (!form.getFieldValue(['products', name, item])?.find((x: any) => x?.selected)) {
                flag = false;
                return;
              }
            }
            if (
              form.getFieldValue(['products', name, item]) === null ||
              form.getFieldValue(['products', name, item]) === undefined
            ) {
              flag = false;
              return;
            }
          }
        });
      }
      // Transaction Validation
      if (flag && intent && validationArray?.transaction?.length !== 0) {
        ['selling_price', 'cd_balance'].forEach((item: any) => {
          if (item === 'selling_price') {
            form.getFieldValue(['products', name, 'plan'])?.forEach((x: any) => {
              if (initialData?.[name]?.newProduct) {
                if (x?.selected && (x?.selling_price === null || x?.selling_price === undefined)) {
                  flag = false;
                  return;
                }
              } else {
                if (x?.selling_price === null || x?.selling_price === undefined) {
                  flag = false;
                  return;
                }
              }
            });
          } else if (
            form.getFieldValue(['products', name, item]) === null ||
            form.getFieldValue(['products', name, item]) === undefined
          ) {
            flag = false;
            return;
          }
        });
      }
      setValid(flag);
    },
    resetPricingMatrix = () => {
      let policyArray = form.getFieldValue('products');
      policyArray[name].pricing_matrix = undefined;
      form.setFieldsValue({ products: policyArray });
      setPricingMatrix(undefined);
      checkValidity();
    },
    onProviderChange = (selectedProvider: any, vArray: Array<string> = []) => {
      let policyArray = form.getFieldValue('products');
      if (validationArray?.display?.includes('tpa') || vArray?.includes('tpa')) {
        setTpaList(initialData?.[name]?.provider?.find((item: any) => item?.id === selectedProvider)?.tpa);
        policyArray[name].tpa = undefined;
        form.setFieldsValue({ products: policyArray });
      }
      if (validationArray?.display?.includes('plan') || vArray?.includes('plan')) {
        let plans = initialData?.[name]?.provider?.find((item: any) => item?.id === selectedProvider)?.plans;
        policyArray[name].plan = plans;
        form.setFieldsValue({ products: policyArray });
        setPlanList(plans);
      }
    };

  useEffect(() => {
    let policyArray = form.getFieldValue('products');
    policyArray[name].valid = valid;
    form.setFieldsValue({ products: policyArray });
    updateButtonState();
  }, [valid]);

  useEffect(() => {
    let policyArray = form.getFieldValue('products');
    policyArray[name].intent = intent;
    form.setFieldsValue({ products: policyArray });
    checkValidity();
  }, [intent]);

  useEffect(() => {
    let policyArray = form.getFieldValue('products');
    policyArray[name].disable = disable;
    form.setFieldsValue({ products: policyArray });
  }, [disable]);

  useEffect(() => {
    if (initialData) {
      if (!updatedFlag) {
        setDisable(initialData?.[name]?.disable);
        setDisableIntent(initialData?.[name]?.disableIntent);
        setDisablePolicyDetails(initialData?.[name]?.disablePolicyDetails);
        setDisableBalance(initialData?.[name]?.disableBalance);
        setIntent(initialData?.[name]?.intent);
        if (initialData?.[name]?.transactions_details?.pricing_type?.[0]?.pricing_matrix) {
          setPricingMatrix(initialData?.[name]?.pricing_matrix);
        }
      } else {
        let policyArray = form.getFieldValue('products');
        setDisable(policyArray[name].disable);
        setIntent(policyArray[name].intent);
        setValid(policyArray[name].valid);
        if (policyArray[name].provider_name) {
          policyArray[name].tpa = policyArray[name]?.tpa_name ?? policyArray[name]?.tpa;
          setPlanList(policyArray[name].plan);
          form.setFieldsValue({ products: policyArray });
        }
        if (policyArray[name]?.pricing_matrix) {
          setPricingMatrix(policyArray[name]?.pricing_matrix);
        }
      }
      setValidationArray(initialData?.[name]?.validationArray);
      setMandatoryArray(initialData?.[name]?.mandatoryArray);
      setUpdatedFlag(true);
    }
  }, [initialData]);

  useEffect(() => {
    if (validationArray) {
      checkValidity();
    }
  }, [validationArray]);

  return (
    <Collapse key={name} expandIconPosition="right">
      <Collapse.Panel
        header={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            <div>
              <Row align="middle" gutter={8}>
                <Col>{initialData?.[name]?.product_type}</Col>
                <Col>
                  {valid ? (
                    <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />
                  ) : (
                    <ExclamationCircleOutlined style={{ color: 'red', fontSize: '16px' }} />
                  )}
                </Col>
              </Row>
            </div>
            <div>
              <Row align="middle">
                {initialData?.[name]?.newProduct && (
                  <Col>
                    <Popconfirm
                      title="Are you sure you don't want to add this product?"
                      okText="Confirm"
                      onConfirm={(event) => {
                        remove(initialData?.[name]?.id);
                        event?.stopPropagation();
                      }}
                    >
                      <span
                        style={{ color: 'green', fontSize: '14px' }}
                        onClick={(event) => {
                          event?.stopPropagation();
                        }}
                      >
                        <DeleteOutlined /> Remove
                      </span>
                    </Popconfirm>
                    <span
                      style={{
                        border: '0.5px solid #E9ECEF',
                        height: '36px',
                        margin: '0 8px',
                      }}
                    ></span>
                  </Col>
                )}
                {initialData?.[name]?.enable_transactions && (
                  <Col>
                    <Tooltip title="Toggle this to enable/disable transactions for this product">
                      <Switch
                        checked={intent}
                        disabled={disableIntent}
                        onClick={(value, event) => {
                          event.stopPropagation();
                          setIntent(value);
                          setDisable(!value);
                        }}
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        }
        key={name}
      >
        {validationArray?.display?.length > 0 && (
          <React.Fragment>
            <div
              style={{
                font: 'normal 600 16px/22px "Inter"',
              }}
            >
              Policy Details
            </div>
            <Row gutter={16} className="mt-16">
              {validationArray?.display?.includes('policy_type') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Policy Type"
                    name={[name, 'policy_type']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disablePolicyDetails, message: 'This field is required' }]}
                  >
                    <Select placeholder="Select Policy Type" disabled={disablePolicyDetails} onChange={checkValidity}>
                      {choices?.policy_type_choices?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item}>
                            {toTitleCase(item)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('provider_name') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Provider Name"
                    name={[name, 'provider_name']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disablePolicyDetails, message: 'This field is required' }]}
                  >
                    <Select
                      placeholder="Select a Provider"
                      disabled={disablePolicyDetails}
                      onChange={(value) => {
                        checkValidity();
                        onProviderChange(value);
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const optionText = typeof option?.children === 'string' ? option.children : '';
                        return optionText.toLowerCase().includes(input.toLowerCase());
                      }}
                    >
                      {[...(initialData?.[name]?.provider || [])]
                        ?.sort((a: any, b: any) => (a?.brand_name || '').localeCompare(b?.brand_name || ''))
                        ?.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item?.id}>
                              {item?.brand_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('tpa') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="TPA"
                    name={[name, 'tpa']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('tpa'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a TPA"
                      disabled={disablePolicyDetails}
                      onChange={checkValidity}
                      notFoundContent="Make sure you've selected a provider first"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const optionText = typeof option?.children === 'string' ? option.children : '';
                        return optionText.toLowerCase().includes(input.toLowerCase());
                      }}
                    >
                      {[...(tpaList || [])]
                        ?.sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                        .map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item?.uuid}>
                              {item?.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('coverage_type') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Coverage Type"
                    name={[name, 'coverage_type']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('coverage_type'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a coverage type"
                      disabled={disablePolicyDetails}
                      onChange={checkValidity}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const optionText = typeof option?.children === 'string' ? option.children : '';
                        return optionText.toLowerCase().includes(input.toLowerCase());
                      }}
                    >
                      {coverageList?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('policy_number') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Policy Number"
                    name={[name, 'policy_number']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('policy_number'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Input onChange={checkValidity} disabled={disablePolicyDetails} />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('deductible') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Deductible"
                    name={[name, 'deductible']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('deductible'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={disablePolicyDetails}
                      onChange={checkValidity}
                      min={0}
                      size="small"
                      addonBefore="₹"
                      formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('esc_deductible') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    label={
                      <>
                        Default ESC Deductible&nbsp;&nbsp;
                        <Tooltip placement="top" title={tooltopDefaultDeductible}>
                          <ExclamationCircleFilled className="icon" />
                        </Tooltip>
                      </>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={[name, `esc_deductible`]}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('esc_deductible'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select placeholder="Select a deductible" onChange={checkValidity} disabled={disablePolicyDetails}>
                      {choices?.fti_deductible?.map((value: any) => (
                        <Select.Option value={value} key={value}>
                          ₹ {value / 100000} Lacs
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('parents_deductible') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    label={
                      <>
                        Default Parent's Deductible&nbsp;&nbsp;
                        <Tooltip placement="top" title={tooltopDefaultDeductible}>
                          <ExclamationCircleFilled className="icon" />
                        </Tooltip>
                      </>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={[name, `parents_deductible`]}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('parents_deductible'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select placeholder="Select a deductible" onChange={checkValidity} disabled={disablePolicyDetails}>
                      {choices?.fti_deductible?.map((value: any) => (
                        <Select.Option value={value} key={value}>
                          ₹ {value / 100000} Lacs
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('start_date') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Start Date"
                    name={[name, 'start_date']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('start_date'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MMM-YYYY"
                      disabledDate={(current: any) => current.isAfter(moment())}
                      onChange={checkValidity}
                      suffixIcon
                      disabled={disablePolicyDetails}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('valid_till') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="End Date"
                    name={[name, 'valid_till']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('valid_till'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MMM-YYYY"
                      onChange={checkValidity}
                      suffixIcon
                      disabled={disablePolicyDetails}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.display?.includes('internal_remarks') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Internal Remarks"
                    name={[name, 'internal_remarks']}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: !disablePolicyDetails && mandatoryArray?.includes('internal_remarks'),
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Input onChange={checkValidity} disabled={disablePolicyDetails} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {initialData?.[name]?.type === 'subscription' &&
              validationArray?.transaction?.includes('selling_price') &&
              form.getFieldValue('products', name, 'plan') &&
              !initialData?.[name]?.enable_transactions &&
              (
                <Col span={24}>
                  <Form.Item
                    {...restField}
                    name={[name, 'service_pricing', 'pricing_type']}
                    initialValue={'PER_LIFE_SELLING_PRICING'}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={24} className="pseudo-table-header">
                    {initialData?.[name]?.newProduct && <Col span={1} />}
                    <Col span={24}>Plan Name</Col>
                  </Row>
                  <Form.List name={[name, 'plan']}>
                    {(plans) => (
                      <>
                        {plans?.map((field) => (
                          <Row gutter={22} key={field.key} align="middle" className="pseudo-table-row">
                            {initialData?.[name]?.newProduct && (
                              <Col span={1}>
                                <Form.Item
                                  {...restField}
                                  name={[field.name, 'selected']}
                                  validateTrigger="onBlur"
                                  valuePropName="checked"
                                  style={{ marginBottom: '0' }}
                                // rules={[{ required: !disable && intent, message: "This field is required" }]}
                                >
                                  <Checkbox onChange={checkValidity} />
                                </Form.Item>
                              </Col>
                            )}
                            {!initialData?.[name]?.newProduct && (
                              <Col span={22}>
                                {initialData?.[name]?.plan?.[field?.name]?.plan_name} - (
                                {initialData?.[name]?.plan?.[field?.name]?.plan_code})
                              </Col>
                            )}
                            {initialData?.[name]?.newProduct && (
                              <Col span={22}>
                                {planList?.[field.name]?.plan_name} - ({planList?.[field.name]?.plan_code})
                              </Col>
                            )}
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
              )}
          </React.Fragment>
        )}
        {validationArray?.transaction?.length > 0 && initialData?.[name]?.enable_transactions && (
          <React.Fragment>
            <div
              style={{
                font: 'normal 600 16px/22px "Inter"',
                marginTop: '8px',
              }}
            >
              Transaction Details
            </div>
            <Row gutter={16} className="mt-16">
              {validationArray?.transaction?.includes('cd_balance') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="CD Balance"
                    name={[name, 'cd_balance']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <InputNumber
                      onChange={checkValidity}
                      disabled={disable || disableBalance}
                      min={0}
                      size="small"
                      addonBefore="₹"
                      formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.transaction?.includes('commission') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Commission"
                    name={[name, 'commission']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <InputNumber
                      onChange={checkValidity}
                      disabled={disable}
                      min={0}
                      max={100}
                      size="small"
                      addonBefore="%"
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.transaction?.includes('source') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={'Booking Source'}
                    name={[name, 'source']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <Select
                      onChange={checkValidity}
                      disabled={disable}
                      options={bookingSources?.map((item: any) => {
                        return { label: item?.name, value: item?.id };
                      })}
                      suffixIcon={!disable && <PlusCircleOutlined className='cta' onClick={()=>setSourceModal(true)} />}
                    />
                  </Form.Item>
                </Col>
              )}
              {sourceModal && <BookingSourceModal cancelHandler={()=>setSourceModal(false)} onSuccess={getBookingSource} isModalVisible={sourceModal} /> }
              {validationArray?.transaction?.includes('inception_payment_frequency') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Inception Payment Frequency"
                    name={[name, 'inception_payment_frequency']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <Select
                      onChange={checkValidity}
                      disabled={disable}
                      options={choices?.transactions_config?.payment_frequency?.filter((item: string) => item === 'YEARLY')?.map((item: any) => {
                        return { label: toTitleCase(item), value: item };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'payment_frequency', 0, 'type']}
                    initialValue={'INCEPTION'}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.transaction?.includes('endorsement_payment_frequency') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Endorsement Payment Frequency"
                    name={[name, 'endorsement_payment_frequency']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <Select
                      onChange={checkValidity}
                      disabled={disable}
                      options={choices?.transactions_config?.payment_frequency?.filter((item: string) => item === 'YEARLY')?.map((item: any) => {
                        return { label: toTitleCase(item), value: item };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'payment_frequency', 1, 'type']}
                    initialValue={'ENDORSEMENT'}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.transaction?.includes('issue_booking_date') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Issue Booking Date"
                    name={[name, 'issue_booking_date']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <DatePicker
                      disabledDate={(current: any) => current.isAfter(moment(initialData?.[name]?.valid_till))}
                      format="DD-MMM-YYYY"
                      suffixIcon
                      onChange={checkValidity}
                      disabled={disable}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
              {validationArray?.transaction?.includes('rewards_amount') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Rewards Amount"
                    name={[name, 'rewards_amount']}
                    validateTrigger="onBlur"
                  >
                    <InputNumber
                      onChange={checkValidity}
                      disabled={disable}
                      min={0}
                      size="small"
                      addonBefore="₹"
                      formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              {validationArray?.transaction?.includes('mille_rate') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Rate Per Mille"
                    name={[name, 'mille_rate']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <InputNumber
                      onChange={checkValidity}
                      disabled={disable}
                      min={0}
                      size="small"
                      addonBefore="₹"
                      formatter={(value) => (value ? parseFloat(value)?.toLocaleString('en-IN') : '')}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'service_pricing', 'pricing_type']}
                    initialValue={'PER_LIFE_MILLE_RATE_PRICING'}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {initialData?.[name]?.type === 'policy' && validationArray?.transaction?.includes('selling_price') && (
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Selling Price"
                    name={[name, 'selling_price']}
                    validateTrigger="onBlur"
                    rules={[{ required: !disable && intent, message: 'This field is required' }]}
                  >
                    <InputNumber
                      onChange={checkValidity}
                      disabled={disable}
                      min={0}
                      size="small"
                      addonBefore="₹"
                      formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'service_pricing', 'pricing_type']}
                    initialValue={'PER_LIFE_SELLING_PRICING'}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {initialData?.[name]?.type === 'subscription' &&
                validationArray?.transaction?.includes('selling_price') &&
                form.getFieldValue('products', name, 'plan') && (
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'service_pricing', 'pricing_type']}
                      initialValue={'PER_LIFE_SELLING_PRICING'}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                    <Row gutter={24} className="pseudo-table-header">
                      {initialData?.[name]?.newProduct && <Col span={1} />}
                      <Col span={9}>Plan Name</Col>
                      <Col span={6}>Cost Price</Col>
                      <Col span={6}>Selling Price</Col>
                    </Row>
                    <Form.List name={[name, 'plan']}>
                      {(plans) => (
                        <>
                          {plans?.map((field) => (
                            <Row gutter={24} key={field.key} align="middle" className="pseudo-table-row">
                              {initialData?.[name]?.newProduct && (
                                <Col span={1}>
                                  <Form.Item
                                    {...restField}
                                    name={[field.name, 'selected']}
                                    validateTrigger="onBlur"
                                    valuePropName="checked"
                                    style={{ marginBottom: '0' }}
                                  // rules={[{ required: !disable && intent, message: "This field is required" }]}
                                  >
                                    <Checkbox onChange={checkValidity} />
                                  </Form.Item>
                                </Col>
                              )}
                              {!initialData?.[name]?.newProduct && (
                                <Col span={9}>
                                  {initialData?.[name]?.plan?.[field?.name]?.plan_name} - (
                                  {initialData?.[name]?.plan?.[field?.name]?.plan_code})
                                </Col>
                              )}
                              {initialData?.[name]?.newProduct && (
                                <Col span={9}>
                                  {planList?.[field.name]?.plan_name} - ({planList?.[field.name]?.plan_code})
                                </Col>
                              )}
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[field.name, 'cost_price']}
                                  validateTrigger="onBlur"
                                  style={{ marginBottom: '0' }}
                                // rules={[{ required: !disable && intent && form.getFieldValue('products', name, 'plan', field.name, 'selected'), message: "This field is required" }]}
                                >
                                  <InputNumber
                                    min={0}
                                    onChange={checkValidity}
                                    disabled
                                    size="small"
                                    addonBefore="₹"
                                    formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                                  // placeholder="Cost Price"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[field.name, 'selling_price']}
                                  validateTrigger="onBlur"
                                  style={{ marginBottom: '0' }}
                                // rules={[{ required: !disable && intent && form.getFieldValue('products', name, 'plan', field.name, 'selected'), message: "This field is required" }]}
                                >
                                  <InputNumber
                                    min={0}
                                    onChange={checkValidity}
                                    disabled={disable}
                                    size="small"
                                    addonBefore="₹"
                                    formatter={(value) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')}
                                    placeholder="Selling Price"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </Col>
                )}
              {validationArray?.transaction?.includes('pricing_matrix') && (
                <>
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={
                        <>
                          <Tooltip
                            title={
                              "We use the pricing matrix to figure out how much each member's premium will be for this product. You can download the template, fill it out, and upload it so we can calculate the premiums."
                            }
                          >
                            <span onClick={(e) => e.preventDefault()}>
                              Pricing Matrix&nbsp;
                              <QuestionCircleFilled />
                            </span>
                          </Tooltip>
                          &nbsp;&nbsp;
                          <a href={PRICING_MATRIX_TEMPLATE} target="_blank" download className="download-template">
                            Download template
                          </a>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span
                            className="cta"
                            onClick={(e) => {
                              setHelpModalVisible(true);
                              e.preventDefault();
                            }}
                          >
                            View instructions
                          </span>
                        </>
                      }
                      name={[name, 'pricing_matrix']}
                      validateTrigger="onBlur"
                      rules={[{ required: !disable && intent, message: 'This field is required' }]}
                    >
                      <Upload
                        multiple={false}
                        onChange={checkValidity}
                        // disabled={disable}
                        maxCount={1}
                        name="file"
                        accept=".xlsx, .xls"
                        onRemove={() => {
                          // checkValidity();
                          resetPricingMatrix();
                        }}
                        beforeUpload={(file) => {
                          const reader = new FileReader();

                          reader.onload = (e) => {
                            const data = e?.target?.result;
                            const workbook = XLSX.read(data, { type: 'array' });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            let json = XLSX.utils.sheet_to_json(worksheet);
                            json = JSON.stringify(json);
                            if (!json?.includes('Upper age band') || !json?.includes('Lower age band')) {
                              notification.error({
                                message: 'Invalid file format',
                                description: 'Please download the template and upload in correct file format.',
                                placement: 'bottom',
                              });
                              resetPricingMatrix();
                              return;
                            }
                            json = json.split('"Upper age band":').join('"upper_age_band":');
                            json = json.split('"Lower age band":').join('"lower_age_band":');
                            json = JSON.parse(json);

                            for (let i = 0; i < json.length; i++) {
                              const obj = json[i];

                              // Check if all values are positive numbers
                              for (let key in obj) {
                                if (obj.hasOwnProperty(key) && (typeof obj[key] !== 'number' || obj[key] < 0)) {
                                  if (!RegExp('^[0-9]+$').test(obj[key])) {
                                    errorNotification(
                                      'File contains invalid premium amounts or age bands. Please check the file and try again.'
                                    );
                                    resetPricingMatrix();
                                    return;
                                  }
                                }
                              }

                              // Check if upper age band is greater than lower age band
                              if (obj.upper_age_band <= obj.lower_age_band) {
                                errorNotification(
                                  'Upper age band is lower than or equal to lower age band. Please check the file and try again.'
                                );
                                resetPricingMatrix();
                                return;
                              }

                              // Check if lower age band of the next row is greater than or equal to the upper age band of the previous row
                              if (i > 0 && obj.lower_age_band <= json[i - 1].upper_age_band) {
                                errorNotification(
                                  'The lower age band of the next row must be greater than the upper age band of the previous row. Please ensure that the age bands are in the correct order'
                                );
                                resetPricingMatrix();
                                return;
                              }
                            }

                            let policyArray = form.getFieldValue('products');
                            policyArray[name].pricing_matrix = json;
                            form.setFieldsValue({ products: policyArray });
                            setPricingMatrix(json);
                          };
                          reader.readAsArrayBuffer(file);

                          // Prevent upload
                          return false;
                        }}
                      >
                        <Button
                          // disabled={disable}
                          onClick={(e: MouseEvent) => e.preventDefault()}
                          dashed={true}
                          type="secondary"
                        >
                          Click to {`${pricingMatrix ? 'Replace' : 'Upload'}`}
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'service_pricing', 'pricing_type']}
                      initialValue={'PER_LIFE_PRICING_MATRIX'}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {pricingMatrix && (
                      <div>
                        <TableComponent
                          columns={columns?.concat(
                            generateCols(form.getFieldValue(['products', name, 'pricing_matrix']))
                          )}
                          dataSource={form.getFieldValue(['products', name, 'pricing_matrix'])}
                          hasPaging={false}
                        />
                      </div>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </React.Fragment>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default AddPolicyTransactions;
